<template>
    <div>
      <div class="footer-bar">
        <a href="https://higherupsmokeshop.com/terms-and-conditions/" target="_blank">
          <v-icon class="mr-1">mdi-file-document-outline</v-icon>
        </a>
        <a href="https://higherupsmokeshop.com/privacy-policy/" target="_blank">
          <v-icon class="mr-1">mdi-lock-outline</v-icon>
        </a>
        <button class="add-to-home-screen" @click="addToHomeScreen">
          <v-icon class="mr-1">mdi-home-plus-outline</v-icon>
        </button>
      </div>
  
      <v-dialog v-model="showIosInstructions" max-width="400px">
        <v-card>
          <v-card-title class="headline">Add to Home Screen</v-card-title>
          <v-card-text>
            <p>To add our app to your home screen, follow these steps:</p>
            <v-row align="center" justify="center" class="instruction-row">
              <v-col cols="6" class="text-center">
                <v-icon class="mdi mdi-share-variant instruction-icon"></v-icon>
                <p>1. Tap the <strong>Share</strong> button</p>
              </v-col>
              <v-col cols="6" class="text-center">
                <v-icon class="mdi mdi-home-plus instruction-icon"></v-icon>
                <p>2. Select <strong>Add to Home Screen</strong></p>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="showIosInstructions = false">Got it!</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        deferredPrompt: null,
        showIosInstructions: false,
      };
    },
    methods: {
      addToHomeScreen() {
        if (this.deferredPrompt) {
          this.deferredPrompt.prompt();
          this.deferredPrompt.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
              console.log('User accepted the A2HS prompt');
            } else {
              console.log('User dismissed the A2HS prompt');
            }
            this.deferredPrompt = null;
          });
        } else {
          this.showIosInstructions = true;
        }
      },
    },
    mounted() {
      window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault();
        this.deferredPrompt = e;
      });
  
      window.addEventListener('appinstalled', () => {
        console.log('PWA was installed');
      });
  
      if (!('serviceWorker' in navigator)) {
        console.log('Service workers are not supported in this browser');
      } else {
        console.log('Service workers are supported');
      }
    },
  };
  </script>
  
  <style scoped>
  .footer-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #232e38;
    padding: 13px;
    color: white;
    font-size: 1rem;
    max-width: 600px;
    margin: 28px auto 0; /* Center horizontally */
    border-radius: 5px;
    height: 64px;
  }
  
  .footer-bar a {
    color: white;
    text-decoration: none;
    display: flex;
    align-items: center;
    margin: 0 10px;
  }
  
  .footer-bar a:hover {
    text-decoration: underline;
  }
  
  .footer-bar v-icon {
    margin-right: 5px;
  }
  
  .add-to-home-screen {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 0 10px;
  }
  
  .add-to-home-screen:hover {
    text-decoration: underline;
  }
  
  .instruction-row {
    margin-top: 16px;
  }
  
  .instruction-icon {
    font-size: 48px;
  }
  
  .v-card-title {
    text-align: center;
    width: 100%;
  }
  </style>
  