import { createApp } from 'vue';
import App from './App.vue';
import { createVuetify } from 'vuetify';
import 'vuetify/styles'; // Ensure you are using css-loader
import '@mdi/font/css/materialdesignicons.css'; // Import MDI CSS
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import VueTheMask from 'vue-the-mask'; // Import vue-the-mask
import router from './router'; // Import the router

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi', // Use MDI as default icon set
  },
});

const app = createApp(App);

app.use(vuetify);
app.use(VueTheMask); // Use vue-the-mask globally
app.use(router); // Use the router

app.mount('#app');

// Register the service worker
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js')
      .then(registration => {
        console.log('ServiceWorker registration successful with scope: ', registration.scope);
      })
      .catch(error => {
        console.log('ServiceWorker registration failed: ', error);
      });
  });
}
