<template>
  <v-app class="full-screen-app">
    <v-main class="full-screen-main">
      <div :class="{'v-container': !removeContainerClass, 'no-padding': true}" fill-height>
        <div v-if="qrCode" class="user-info-container animate__animated animate__tada delay-200ms" :class="{ 'user-details-visible': detailsVisible }">
          <v-icon v-if="!detailsVisible" class="user-icon" @click="toggleDetails">
            mdi-account
          </v-icon>

          <div class="user-details">
            <ul>
              <li @click="toggleDetails" class="close-icon">
                <v-icon class="mr-2">mdi-close</v-icon>
                <strong>Close</strong>
              </li>
              <li>
                <v-icon class="mr-2">mdi-email</v-icon>
                <strong></strong>{{ user.email }}
              </li> 
              <li>
                <v-icon class="mr-2">mdi-phone</v-icon>
                <strong></strong>{{ formattedPhone }}
              </li>
              <li>
                <v-icon class="mr-2">mdi-cake</v-icon>
                <strong></strong>{{ user.birthday }}
              </li>
              <li @click="logout" class="logout-link">
                <v-icon class="mr-2">mdi-logout</v-icon>Logout
              </li>
            </ul>
          </div>
        </div>
        <v-row align="center" justify="center" class="no-padding">
          <v-card class="custom-card full-screen-card" v-if="!userAuthenticated">
            <v-card-title class="title">
              <v-img
                src="@/assets/cropped-full-logo.png"
                class="mx-auto mb-4 title-logo-class"
              ></v-img>
            </v-card-title>
            <v-card-text>
              <v-toolbar flat class="text-center osiris-font reward-title">
                {{ formSubmitted ? 'Success! Please check your email.' : 'Claim your 7$ Reward!' }}
              </v-toolbar>

              <div v-if="!formSubmitted">
                <v-stepper class="custom-stepper" mobile v-model="step" :items="items" show-actions>
                  <template v-slot:[`item.1`]>
                    <div>
                      <v-text-field
                        ref="phoneField"
                        v-model="form.phone"
                        v-mask="'(###) ###-####'"
                        label="Phone"
                        type="tel"
                        :rules="phoneRules"
                        required
                        outlined
                        :error-messages="errors.phone"
                      ></v-text-field>
                      <div v-html="phoneHint" class="custom-hint"></div>
                    </div>
                  </template>
                  <template v-slot:[`item.2`]>
                    <div v-if="verificationSent">
                      <v-text-field
                        ref="verificationCodeField"
                        v-model="form.verificationCode"
                        label="Verification Code"
                        type="tel"
                        :rules="requiredRules"
                        required
                        outlined
                        hint="Enter the code sent to your phone"
                        persistent-hint
                        :error-messages="errors.verificationCode"
                      ></v-text-field>
                    </div>

                  </template>
                  <template v-slot:[`item.3`]>
                    <div>
                      <v-text-field
                        ref="firstNameField"
                        v-model="form.firstName"
                        label="First Name"
                        :rules="requiredRules"
                        required
                        outlined
                        hint="* Required"
                        persistent-hint
                        :error-messages="errors.firstName"
                      ></v-text-field>
                      <v-text-field
                        ref="lastNameField"
                        v-model="form.lastName"
                        label="Last Name"
                        :rules="requiredRules"
                        required
                        outlined
                        hint="* Required"
                        persistent-hint
                        :error-messages="errors.lastName"
                      ></v-text-field>
                    </div>
                  </template>
                  <template v-slot:[`item.4`]>
                    <div>
                      <v-text-field
                        ref="emailField"
                        v-model="form.email"
                        label="Email Address"
                        type="email"
                        :rules="emailRules"
                        required
                        outlined
                        hint="* Required"
                        persistent-hint
                        :error-messages="errors.email"
                      ></v-text-field>
                    </div>
                  </template>
                  <template v-slot:[`item.5`]>
                    <div>
                      <v-text-field
                        ref="birthdayField"
                        v-model="form.birthday"
                        label="Birthday"
                        v-mask="'##/##/####'"
                        placeholder="MM/DD/YYYY"
                        :rules="birthdayRules"
                        required
                        outlined
                        hint="Thank you for sharing and happy early-birthday!!"
                        persistent-hint
                        :error-messages="errors.birthday"
                         @blur="validateBirthday"
                      ></v-text-field>
                    </div>
                  </template>
                  <template v-slot:actions="{ next, prev }">
                    <div class="stepper-actions">
                      <v-btn
                        v-if="step !== 1"
                        class="v-btn v-btn--contained v-stepper__button v-stepper__button--previous"
                        style="background-color:white !important;"
                        @click="prevStep(prev)"
                      >
                        Previous
                      </v-btn>
                      <v-btn
                        :class="step === 1 ? 'v-stepper__button--full' : ''"
                        class="v-btn v-btn--contained v-stepper__button v-stepper__button--next"
                        @click="handleNextStep(next)"
                      >
                        {{ getNextButtonText() }}
                      </v-btn>
                    </div>
                  </template>
                </v-stepper>           
              </div>
              <FooterBar />          
            </v-card-text>
          </v-card>
          <v-card class="custom-card full-screen-card" v-else>  
            <v-card-title class="title">
              <div class="video-container">
                <video
                  ref="video"
                  class="title-video-class"
                  autoplay
                  muted
                  playsinline
                  preload="auto"
                  @ended="onVideoEnded"
                  @loadedmetadata="onLoadedMetadata"
                  @timeupdate="onTimeUpdate"
                ></video>
              </div>
            </v-card-title>

            <v-card-text>             
              <v-toolbar flat class="text-center osiris-font reward-title" >
                <v-toolbar-title
                  :class="shouldAnimate ? 'animate__animated animate__pulse animate__infinite' : 'animate__animated animate__flipInX'"
                  style="width: 100%; display: flex; justify-content: center; align-items: center;"
                >
                  <span>{{ getRewardPassMessage }}</span>
                           
                </v-toolbar-title>
              </v-toolbar>

              <div class="qr-code-container">
                <template v-if="shouldApplyEffectsWithDelay">
                  <div style="width:100%;" id = "spinner-id" class="animate__animated animate__flipInX">
                    <div class="trippy-spinner">
                      <svg width="120" height="120" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="url(#gradient)" stroke-width="2">
                        <defs>
                          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%">
                            <stop offset="0%" style="stop-color: #ff007a; stop-opacity: 0.5;" />
                            <stop offset="50%" style="stop-color: #1f51ff; stop-opacity: 0.5;" />
                            <stop offset="100%" style="stop-color: #00ff88; stop-opacity: 0.5;" />
                          </linearGradient>
                        </defs>
                        <circle cx="50" cy="50" r="45" stroke-dasharray="283" stroke-dashoffset="75" stroke-linecap="round">
                          <animate attributeName="stroke-dashoffset" from="0" to="283" dur="1.5s" repeatCount="indefinite"/>
                          <animate attributeName="stroke" values="#ff007a;#00ff88;#1f51ff;#ff007a" dur="6s" repeatCount="indefinite"/>
                        </circle>
                        <circle cx="50" cy="50" r="35" stroke-dasharray="220" stroke-dashoffset="55" stroke-linecap="round">
                          <animate attributeName="stroke-dashoffset" from="0" to="220" dur="1.2s" repeatCount="indefinite" begin="0.5s"/>
                          <animate attributeName="stroke" values="#1f51ff;#ff007a;#00ff88;#1f51ff" dur="6s" repeatCount="indefinite"/>
                        </circle>
                        <circle cx="50" cy="50" r="25" stroke-dasharray="157" stroke-dashoffset="40" stroke-linecap="round">
                          <animate attributeName="stroke-dashoffset" from="0" to="157" dur="1s" repeatCount="indefinite" begin="1s"/>
                          <animate attributeName="stroke" values="#00ff88;#1f51ff;#ff007a;#00ff88" dur="6s" repeatCount="indefinite"/>
                        </circle>
                      </svg>
                    </div>
                  </div>
                </template>
                <template v-else-if="isRewardRedeemed">
                  <div class="animate__animated animate__fadeIn custom-reward-message">
                    <p v-if="isToday">We hope you enjoyed your visit to our {{ lastClaimedStore }} shop today.</p>
                    <p v-else>We hope you enjoyed your visit to our {{ lastClaimedStore }} shop on {{ formattedLastClaimedDate }}.</p>
                    <p>We look forward to seeing you again!</p>
                    <div class="celebration-footer">
                      <v-icon medium color="indigo darken-3">mdi-emoticon-excited</v-icon>
                      <v-icon medium color="teal darken-2">mdi-trophy</v-icon>
                      <v-icon medium color="cyan darken-2">mdi-firework</v-icon>
                    </div>
                  </div>
                </template>
                <template v-else>               
                  <qrcode-vue
                    :value="qrCode"
                    class="qr-code animate__animated animate__fadeIn animate__delay-1s"
                    :size="350"
                  />
                </template>
              </div>  

              <div v-if="nearestStore" class="nearest-store-container">
                <v-toolbar flat class="text-center osiris-font reward-title">
                  <v-toolbar-title
                    style="width: 100%; display: flex; justify-content: center; align-items: center;"
                  >
                    <span>Higher Up Nearest You</span>
                  </v-toolbar-title>
                </v-toolbar>

                <v-card-text class="nearest-store-details">
                <table class="store-info-table">
                  <tr class="store-name">
                    <td><v-icon class="mdi mdi-storefront-outline"></v-icon></td>
                    <td><strong>{{ nearestStore.storename }}</strong></td>
                  </tr>
                  <tr class="store-distance">
                    <td><v-icon class="mdi mdi-map-marker-distance"></v-icon></td>
                    <td>{{ nearestStoreDistance }} miles away</td>
                  </tr>
                  <tr class="store-address">
                    <td><v-icon class="mdi mdi-map-marker"></v-icon></td>
                    <td>{{ nearestStore.store_address }} {{ nearestStore.store_city }}, {{ nearestStore.store_state }} {{ nearestStore.store_zip }}</td>
                  </tr>

                  <tr class="store-phone">
                    <td><v-icon class="mdi mdi-phone"></v-icon></td>
                    <td>{{ formattedPhoneNumberNearestStore }}</td>
                  </tr>
                </table>
              </v-card-text>
              </div>
            </v-card-text>
            <FooterBar />           
          </v-card>  
                
        </v-row>
      </div>

      <v-snackbar
        v-model="snackbar.show"
        :color="snackbar.color"
        :timeout="snackbar.timeout"
        top
      >
        {{ snackbar.message }}
      </v-snackbar>
    </v-main>
    <template>
      <div class="footer-bar">
        <a href="https://higherupsmokeshop.com/terms-and-conditions/" target="_blank">
          <v-icon class="mr-1">mdi-file-document-outline</v-icon> Terms of Service
        </a>
        |
        <a href="https://higherupsmokeshop.com/privacy-policy/" target="_blank">
          <v-icon class="mr-1">mdi-lock-outline</v-icon> Privacy Policy
        </a>
      </div>
    </template>    
  </v-app>
</template>


<script>
import { ref, watch, onMounted,  nextTick, computed } from 'vue';
import axios from 'axios';
import { useRoute } from 'vue-router';
import QrcodeVue from 'qrcode.vue';
import { VueMaskDirective } from 'vue-the-mask';
import { mdiEmail, mdiPhone, mdiCake, mdiCalendar, mdiLogout } from '@mdi/js';
import dayjs from 'dayjs';
import FooterBar from './components/FooterBar.vue';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isToday from 'dayjs/plugin/isToday';
import Cookies from 'js-cookie';
import 'animate.css';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(isToday);

export default {
  name: 'App',
  components: {
    QrcodeVue,
    FooterBar,
  },
  directives: {
    mask: VueMaskDirective,
  },
  setup() {
    const isSending = ref(false);
    const isPhoneValidated = ref(false);
    const userAuthenticated = ref(false);
    const user = ref({});
    const route = useRoute();
    const twillio_sid = ref(null);
    const twillio_to = ref(null);
    const qrCode = ref('');
    const dialog = ref(false);
    const step = ref(1);

    const form = ref({
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      verificationCode: '',
      birthday: '',
    });

    const nearestStore = ref(null);
    const stores = ref([]);
    const userLocation = ref(null);

    const requestLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            userLocation.value = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            findClosestStore(userLocation.value); // Pass userLocation value
          },
          () => {
            //alert('Error: The Geolocation service failed, please select a store manually.');
          }
        );
      } else {
        //alert('Error: This browser does not support geolocation.');
      }
    };

    const findClosestStore = (userLocation) => {
      let closestStore = null;
      let shortestDistance = Number.MAX_VALUE;

      stores.value.forEach(store => {
        //console.log('Store:', store);
        const distance = calculateDistance(
          userLocation.lat,
          userLocation.lng,
          parseFloat(store.store_lat),
          parseFloat(store.store_lon)
        );

        //console.log('Distance to store:', store.storename, 'is', distance);

        if (distance < shortestDistance) {
          shortestDistance = distance;
          closestStore = store;
          //console.log('New closest store:', closestStore);
        }
      });

      nearestStore.value = closestStore;
      //console.log('Final closest store:', nearestStore.value);
    };


    const nearestStoreDistance = computed(() => {
      if (nearestStore.value && userLocation.value) {
        const distanceKm = calculateDistance(
          userLocation.value.lat,
          userLocation.value.lng,
          parseFloat(nearestStore.value.store_lat),
          parseFloat(nearestStore.value.store_lon)
        );
        const distanceMiles = distanceKm * 0.621371; // Convert km to miles
        return distanceMiles.toFixed(2);
      }
      return '';
    });

    const calculateDistance = (lat1, lon1, lat2, lon2) => {
      const toRad = (Value) => Value * Math.PI / 180;
      const R = 6371; // km
      const dLat = toRad(lat2 - lat1);
      const dLon = toRad(lon2 - lon1);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      return R * c;
    };

    const fetchStores = async () => {
      try {
        const response = await axios.get('https://higherup.live/application/tools/qr_get_stores.php');
        if (response.data.success) {
          stores.value = response.data.stores;
        } else {
          console.error('Failed to fetch stores:', response.data.message);
        }
      } catch (error) {
        console.error('Failed to fetch stores:', error);
      }
    };


    const detailsVisible = ref(false);

    const toggleDetails = () => {
      detailsVisible.value = !detailsVisible.value;
    };

    const items = ref([
      { title: 'Phone' },
      { title: 'Verification Code' },
      { title: 'Full Name' },
      { title: 'Email Address' },
      { title: 'Birthday' },
    ]);

    const formSubmitted = ref(false);
    const verificationSent = ref(false);

    const errors = ref({
      email: [],
      firstName: [],
      lastName: [],
      phone: [],
      verificationCode: [],
      birthday: [],
    });

    const snackbar = ref({
      show: false,
      message: '',
      color: '',
      timeout: 3000,
    });

    const showSnackbar = (message, type) => {
      snackbar.value.message = message;
      snackbar.value.color = type === 'success' ? 'green' : 'red';
      snackbar.value.show = false;
      nextTick(() => {
        snackbar.value.show = true;
      });
    };

    const phoneHint = ref(
      "Please provide your phone number to register your reward. By doing so, you'll also be added to our mailing list and may receive occasional promotional offers. <br><br>" 
    );


    const emailField = ref(null);
    const fullNameField = ref(null);
    const phoneField = ref(null);
    const verificationCodeField = ref(null);
    const birthdayField = ref(null);
    const qrStatus = ref({
      posted: null,
      rewards_active: null,
      first_name: null,
      last_name: null,
      last_rewards_claimed_store: null,
      last_rewards_claimed_date: null,
      last_rewards_claimed_employee: null,
    });

    const requiredRules = [
      v => !!v || 'This field is required',
    ];

    const emailRules = [
      ...requiredRules,
      v => /.+@.+\..+/.test(v) || 'Email must be valid',
    ];

    const phoneRules = [
      v => !!v || 'This field is required',
      v => /^\(\d{3}\) \d{3}-\d{4}$/.test(v) || 'Phone number must be valid',
    ];

    const validateBirthday = value => {
      if (!value) return 'This field is required';
      
      const datePattern = /^\d{2}\/\d{2}\/\d{4}$/; // MM/DD/YYYY
      if (!datePattern.test(value)) {
        return 'Please enter a valid date in MM/DD/YYYY format';
      }

      const parts = value.split('/');
      const month = parseInt(parts[0], 10);
      const day = parseInt(parts[1], 10);
      const year = parseInt(parts[2], 10);

      if (year < 1900) {
        return 'Year must be 1900 or later';
      }

      if (month < 1 || month > 12 || day < 1 || day > 31) {
        return 'Invalid date';
      }

      const birthDate = new Date(year, month - 1, day);
      if (birthDate.getFullYear() !== year || birthDate.getMonth() !== month - 1 || birthDate.getDate() !== day) {
        return 'Invalid date';
      }

      const today = new Date();
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDifference = today.getMonth() - birthDate.getMonth();

      if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }

      return age >= 18 ? true : 'You must be at least 18 years old';
    };

    const birthdayRules = [
      validateBirthday,
    ];

    const validateField = async (field, rules) => {
      errors.value[field] = await Promise.all(
        rules.map(async rule => await rule(form.value[field]))
      ).then(results => results.filter(error => error !== true));
    };

    const isStepValid = async (step) => {
      let isValid = false;
      switch (step) {
        case 1: {
          const phoneValidationResults = await Promise.all(phoneRules.map(rule => rule(form.value.phone)));
          isValid = form.value.phone && phoneValidationResults.every(result => result === true);
          break;
        }
        case 2: {
          const verificationResults = requiredRules.map(rule => rule(form.value.verificationCode));
          isValid = form.value.verificationCode && verificationResults.every(result => result === true);
          break;
        }
        case 3: {
          const firstNameResults = await Promise.all(requiredRules.map(rule => rule(form.value.firstName)));
          const lastNameResults = await Promise.all(requiredRules.map(rule => rule(form.value.lastName)));
          isValid = form.value.firstName && firstNameResults.every(result => result === true) && form.value.lastName && lastNameResults.every(result => result === true);
          break;
        }
        case 4: {
          const emailResults = await Promise.all(emailRules.map(rule => rule(form.value.email)));
          isValid = form.value.email && emailResults.every(result => result === true);
          break;
        }
        case 5: {
          const birthdayResults = await Promise.all(birthdayRules.map(rule => rule(form.value.birthday)));
          isValid = form.value.birthday && birthdayResults.every(result => result === true);
          break;
        }
        default:
          isValid = false;
      }
      return isValid;
    };

    const validateAllFields = (step) => {
      switch (step) {
        case 1:
          validateField('phone', phoneRules);
          break;
        case 2:
          validateField('verificationCode', requiredRules);
          break;
        case 3:
          validateField('firstName', requiredRules);
          validateField('lastName', requiredRules); 
          break;
        case 4:
          validateField('email', emailRules);
          break;
        case 5:
          validateField('birthday', birthdayRules);
          break;
      }
    };

    const nextStep = async (next) => {
      await validateAllFields(step.value);
      if (await isStepValid(step.value)) {
        if (step.value === 1) {
          await sendVerificationCode();
        } else if (step.value === 2) {
          await verifyCode();
        } else if (step.value === 5) {
          await submitForm();
        } else {
          next();
          nextTick(() => {
            focusInput(step.value + 1);
          });
        }
      }
    };

    const prevStep = (prev) => {
      prev();
      nextTick(() => {
        focusInput(step.value - 1);
      });
    };

    const validatePhone = async (phone) => {
      const formattedPhone = phone.replace(/\D/g, '');
      try {
        const response = await axios.get(`https://higherup.live/application/tools/qr_check_phone.php?phone=${formattedPhone}`);
        if (response.data.exists) {
          const userData = {
            firstName: response.data.user.first_name,
            lastName: response.data.user.last_name,
            birthday: response.data.user.birthday,
            email: response.data.user.email,
            phone: response.data.user.phone,
            added: response.data.user.added,
            reward_id: response.data.user.reward_id
          };
          qrCode.value = userData.reward_id;
          setUserInStorage(userData); // Updated to use setUserInStorage
          return {
            exists: true,
            user: userData
          };
        } else {
          return { exists: false };
        }
      } catch (error) {
        showSnackbar('Failed to validate phone number', 'error');
        return { exists: false };
      }
    };

    const sendVerificationCode = async () => {
      if (isSending.value) return;

      isSending.value = true;
      const formattedPhone = form.value.phone.replace(/\D/g, '');
      try {
        const response = await axios.post('https://higherup.live/application/tools/qr_send_code.php', { phone: formattedPhone });
        if (response.data.errors && response.data.errors.length > 0) {
          showSnackbar(response.data.errors[0].description, 'error');
        } else {
          twillio_to.value = response.data.to;
          twillio_sid.value = response.data.sid;
          verificationSent.value = true;
          showSnackbar('Verification code on the way!', 'success');
          step.value = 2;
        }
      } catch (error) {
        showSnackbar('Failed to send verification code', 'error');
      } finally {
        isSending.value = false;
      }
    };

    const verifyCode = async () => {
      try {
        const response = await axios.post('https://higherup.live/application/tools/qr_verify_code.php', {
          twillio_to: twillio_to.value,
          twillio_sid: twillio_sid.value,
          otp: form.value.verificationCode
        });

        console.log('Response from server:', response.data);

        if (response.data.errors && response.data.errors.length > 0) {
          showSnackbar(response.data.errors[0].description, 'error');
        } else if (response.data.valid) { // Check the 'valid' field in the response
          console.log('Verification successful');
          const phoneExists = await validatePhone(form.value.phone);
          console.log('Phone validation result:', phoneExists);

          if (phoneExists.exists) {
            userAuthenticated.value = true;
            user.value = phoneExists.user;
            setUserInStorage(phoneExists.user);
            startPolling();
            initializeVideoPlayback();
            showSnackbar('Welcome Back!', 'success');
          } else {
            step.value = 3;
            showSnackbar('Let\'s setup your account!', 'success');
          }
        } else {
          console.log('Verification failed:', response.data);
          showSnackbar('Failed to verify code 1', 'error');
        }
      } catch (error) {
        console.error('Error during verification:', error);
        showSnackbar('Failed to verify code 2', 'error');
      }
    };

    const formattedPhone = computed(() => {
      const phone = user.value.phone;
      if (phone) {
        const match = phone.match(/(\d{3})(\d{3})(\d{4})/);
        return match ? `(${match[1]}) ${match[2]}-${match[3]}` : phone;
      }
      return '';
    });

    const formattedPhoneNumberNearestStore = computed(() => {
      const phone = nearestStore.value.store_phone;
      if (phone) {
        const match = phone.match(/(\d{3})(\d{3})(\d{4})/);
        return match ? `(${match[1]}) ${match[2]}-${match[3]}` : phone;
      }
      return '';
    });


    const formatAddedDate = (date) => {
      return dayjs(date).format('ddd, MMM Do YYYY');
    };

    const submitForm = async () => {
      await validateAllFields(step.value);
      if (await isStepValid(step.value)) {
        const cleanedPhone = parsePhoneNumber(form.value.phone);
        const formattedBirthday = dayjs(form.value.birthday, 'MM/DD/YYYY').format('YYYY-MM-DD');
        const payload = {
          email: form.value.email,
          firstName: form.value.firstName,
          lastName: form.value.lastName,
          phone: cleanedPhone,
          birthday: formattedBirthday,
        };

        try {
          const response = await axios.post('https://higherup.live/application/tools/qr_insert_signup.php', payload);
          if (response.data.success) {
            showSnackbar('Form submitted successfully', 'success');
            formSubmitted.value = true;
            userAuthenticated.value = true;
            user.value = {
              ...payload,
              added: formatAddedDate(new Date()), 
              reward_id: response.data.reward_id
            };
            qrStatus.value.posted = null;
            qrStatus.value.posted = 0;
            qrCode.value = response.data.reward_id;
            initializeVideoPlayback();
            setUserInStorage(user.value);
            startPolling();
          } else {
            throw new Error(response.data.error || 'Failed to submit form');
          }
        } catch (error) {
          showSnackbar(error.message || 'Failed to submit form', 'error');
        }
      } else if (errors.value.birthday.length > 0) {
        showSnackbar(errors.value.birthday[0], 'error');
      }
    };

    const parsePhoneNumber = (phone) => {
      return phone.replace(/\D/g, '');
    };

    const focusInput = (step) => {
      switch (step) {
        case 1:
          phoneField.value && phoneField.value.focus();
          break;
        case 2:
          verificationCodeField.value && verificationCodeField.value.focus();
          break;
        case 3:
          fullNameField.value && fullNameField.value.focus();
          break;
        case 4:
          emailField.value && emailField.value.focus();
          break;
        case 5:
          birthdayField.value && birthdayField.value.focus();
          break;
      }
    };

    const getNextButtonText = () => {
      switch (step.value) {
        case 1:
          return 'Send Code';
        case 2:
          return 'Verify Code';
        case 5:
          return 'Submit';
        default:
          return 'Next';
      }
    };

    const initializeDialog = async () => {
      dialog.value = true;
      qrCode.value = route.query.qr_code || null;
      await nextTick();
      focusInput(step.value);
    };

    const localStorageStrategy = ref('');

    const setUserInStorage = async (userData) => {
      const dataString = encodeURIComponent(JSON.stringify(userData));
      try {
        localStorage.setItem('authenticatedUser', dataString);
        localStorageStrategy.value = 'Local Storage';
      } catch (e) {
        console.error('Failed to set Local Storage', e);
        try {
          Cookies.set('authenticatedUser', dataString, { expires: 7 });
          localStorageStrategy.value = 'Cookies';
        } catch (cookieError) {
          console.error('Failed to set Cookies', cookieError);
        }
      }
    };

    const getUserFromStorage = async () => {
      let userData = null;
      try {
        userData = localStorage.getItem('authenticatedUser');
        if (userData) {
          localStorageStrategy.value = 'Local Storage';
          return JSON.parse(decodeURIComponent(userData));
        }
      } catch (e) {
        console.error('Failed to get Local Storage', e);
      }
      try {
        userData = Cookies.get('authenticatedUser');
        if (userData) {
          localStorageStrategy.value = 'Cookies';
          return JSON.parse(decodeURIComponent(userData));
        }
      } catch (e) {
        console.error('Failed to get Cookies', e);
      }
      return null;
    };

    const formatDate = (timestamp) => {
      const date = new Date(timestamp * 1000);
      return date.toLocaleDateString();
    };

    const removeContainerClass = ref(true);

    const isUserLoggedInLocally = () => {
      return !!Cookies.get('authenticatedUser');
    };

    const checkUserStatus = async () => {
      try {
        const response = await axios.get(`https://higherup.live/application/tools/qr_check_user_status.php?phone=${user.value.phone}`);
        if (!response.data.exists) {
          console.log ( 'Bad Interval Check...');
        } else {
          qrStatus.value.posted = response.data.user.posted;
          qrStatus.value.rewards_active = response.data.user.rewards_active;
          qrStatus.value.posted_stores = response.data.user.posted_stores;
          qrStatus.value.total_active_shops = response.data.user.total_active_shops;
          qrStatus.value.first_name = response.data.user.first_name;
          qrStatus.value.last_name = response.data.user.last_name;
          qrStatus.value.last_rewards_claimed_store = response.data.user.last_rewards_claimed_store;
          qrStatus.value.last_rewards_claimed_date = response.data.user.last_rewards_claimed_date;
          qrStatus.value.last_rewards_claimed_employee = response.data.user.last_rewards_claimed_employee;
        }
      } catch (error) {
        console.log ( 'Can not phone home...');
      }
    };

    const startPolling = () => {
      if (pollingInterval.value) clearInterval(pollingInterval.value);
      pollingInterval.value = setInterval(checkUserStatus, 2000);
    };

    const stopPolling = () => {
      if (pollingInterval.value) clearInterval(pollingInterval.value);
    };

    const pollingInterval = ref(null);

    const getRewardPassMessage = computed(() => {
      if (qrStatus.value.posted === null ) {
        if (qrStatus.value.posted_stores > 0) {
          const percentage = ((qrStatus.value.posted_stores / qrStatus.value.total_active_shops) * 100).toFixed(2);
          return `Sign up status: ${percentage}%`;
        }
        return 'Looking up rewards';
      }
      if (qrStatus.value.posted === 0) {
        return 'Looking up rewards';
      }
      if (qrStatus.value.rewards_active === 0) {
        return 'Redeemed!';
      }      
      const lastNameInitial = qrStatus.value.last_name ? qrStatus.value.last_name.charAt(0) : '';
      return `Reward Pass for ${qrStatus.value.first_name} ${lastNameInitial}!`;
    });

    const logout = () => {
      try {
        localStorage.removeItem('authenticatedUser');
      } catch (e) {
        console.error('Failed to remove Local Storage', e);
      }
      Cookies.remove('authenticatedUser');

      form.value = {
        email: '',
        firstName: '',
        lastName: '',
        phone: '',
        verificationCode: '',
        birthday: '',
      };

      errors.value = {
        email: [],
        firstName: [],
        lastName: [],
        phone: [],
        verificationCode: [],
        birthday: [],
      };

      verificationSent.value = false;
      formSubmitted.value = false;
      userAuthenticated.value = false;
      detailsVisible.value = false;

      step.value = 1;

      qrCode.value = '';

      showSnackbar("You have been successfully logged out.", "info");

      stopPolling();
    };

    const isToday = computed(() => {
      return qrStatus.value.last_rewards_claimed_date && dayjs(qrStatus.value.last_rewards_claimed_date).isToday();
    });

    const formattedLastClaimedDate = computed(() => {
      return qrStatus.value.last_rewards_claimed_date 
        ? dayjs(qrStatus.value.last_rewards_claimed_date).format('ddd, MMM Do \'YY')
        : '';
    });

    const shouldApplyEffects = computed(() => {
      console.log('qrStatus.value.posted:', qrStatus.value.posted);
      console.log('qrStatus.value.rewards_active:', qrStatus.value.rewards_active);

      if (qrStatus.value.posted === null) {
        console.log('Returning true because qrStatus.value.posted is null');
        return true;
      }
      
      if (qrStatus.value.posted === 0) {
        console.log('Returning true because qrStatus.value.posted is 0');
        return true;
      }
      
      if (qrStatus.value.rewards_active === null) {
        console.log('Returning true because qrStatus.value.rewards_active is null');
        return true;
      }
      
      if (qrStatus.value.rewards_active === 0) {
        // theyve redeemed
        console.log('Returning true because qrStatus.value.rewards_active is 0');
        return false;
      }
      // CAN WE JUST WAIT HERE?? 300 MIS
      console.log('Returning false');
      return false;
    });

    const shouldApplyEffectsWithDelay = ref(shouldApplyEffects.value);

    const isRewardRedeemed = computed(() => {
      return qrStatus.value.rewards_active === 0;
    });

    const lastClaimedStore = computed(() => qrStatus.value.last_rewards_claimed_store);
    const lastClaimedDate = computed(() => qrStatus.value.last_rewards_claimed_date);
    const lastClaimedEmployee = computed(() => qrStatus.value.last_rewards_claimed_employee);

    const shouldAnimate = computed(() => {
      console.log('qrStatus.value:', qrStatus.value);
      console.log('qrStatus.value.posted:', qrStatus.value.posted);

      if (qrStatus.value && qrStatus.value.posted === null) {
        console.log('Returning true because qrStatus.value.posted is null');
        return true;
      }
      
      if (qrStatus.value && qrStatus.value.posted === 0) {
        console.log('Returning true because qrStatus.value.posted is 0');
        return true;
      }

      console.log('Returning false');
      return false;
    });

    const video = ref(null);
    const seg2Video = require('@/assets/seg2.mp4');
    const seg1Video = require('@/assets/seg1.mp4');
    let seg2Start = 0;
    let seg2End = 0;    

    const initializeVideoPlayback = async () => {
      window.scrollTo(0, 0);
      
      setTimeout(async () => {
        await nextTick();
        if (video.value) {
          const seg1Path = seg1Video.default || seg1Video;
          video.value.src = seg1Path;
          video.value.load();
          video.value.addEventListener('loadedmetadata', onLoadedMetadata);
          video.value.addEventListener('timeupdate', onTimeUpdate);
          video.value.addEventListener('ended', onVideoEnded);
          video.value.play().catch(error => {
            console.error('Error playing seg1.mp4:', error);
          });
        } else {
          console.log('Video element is not accessible in initializeVideoPlayback');
        }
      }, 100);
    };

    onMounted(async () => {
      var Tawk_API = Tawk_API || {};
      (function() {
        var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
        s1.async = true;
        s1.src = 'https://embed.tawk.to/66673ffa981b6c56477ba982/1i01l3qpr';
        s1.setAttribute('crossorigin', '*');
        s0.parentNode.insertBefore(s1, s0);
      })();

      // User authentication
      const storedUser = await getUserFromStorage();
      console.log(localStorageStrategy.value);
      if (storedUser) {
        user.value = storedUser;
        userAuthenticated.value = true;
        qrCode.value = storedUser.reward_id;
        startPolling();
      } else {
        await initializeDialog();
      }

      // Fetch stores and request location after mounting
      await fetchStores();
      requestLocation(); // Request location after fetching stores
      
      initializeVideoPlayback();
    });

    watch(nearestStore, (newVal) => {
      console.log('Nearest Store watch:', newVal);
    });

    watch(detailsVisible, () => {
      // Handle changes to detailsVisible here if necessary
    });

    watch(() => form.value.phone, async () => {
      await validateField('phone', phoneRules);
    });

    watch(() => form.value.verificationCode, async () => {
      await validateField('verificationCode', requiredRules);
    });

    watch(() => form.value.fullName, async () => {
      await validateField('fullName', requiredRules);
    });

    watch(() => form.value.email, async () => {
      await validateField('email', emailRules);
    });

    watch(() => form.value.birthday, async () => {
      await validateField('birthday', birthdayRules);
    });

    watch(() => step.value, async (newStep, oldStep) => {
      if (newStep > oldStep && !await isStepValid(oldStep)) {
        step.value = oldStep;
      } else {
        await nextTick();
        focusInput(newStep);
      }
    });

    watch(shouldApplyEffects, (newVal) => {
      if (!newVal) {
        const spinnerElement = document.getElementById('spinner-id');
        if (spinnerElement) {
          spinnerElement.classList.remove('animate__flipInX');
          spinnerElement.classList.add('animate__fadeOut');     
        }
        setTimeout(() => {
          shouldApplyEffectsWithDelay.value = false;
        }, 750); //  
      } else {
        shouldApplyEffectsWithDelay.value = true;
      }
    });
  
    watch(
      () => isUserLoggedInLocally(),
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          if (newValue) {
            startPolling();
          } else {
            stopPolling();
          }
        }
      }
    );

    const onVideoEnded = async () => {
      if (video.value) {
        if (video.value.src.includes('seg1')) { 
          video.value.src = seg2Video.default || seg2Video;
          video.value.load();
          await new Promise(resolve => setTimeout(resolve, 100)); 
          video.value.play();
        } else {
          video.value.currentTime = seg2Start;
          video.value.play();
        }
      }
    };

    const onTimeUpdate = () => {
      if (video.value && video.value.src.includes('seg2.mp4')) {
        const currentTime = video.value.currentTime;
        if (currentTime >= seg2End) {
          video.value.currentTime = seg2Start;
        }
      }
    };

    const onLoadedMetadata = () => {
      if (video.value) {
        if (video.value.src.includes('seg2.mp4')) {
          seg2Start = 0;
          seg2End = video.value.duration;
        }
      }
    };
    
    return {
      video,
      onVideoEnded,
      onTimeUpdate,
      onLoadedMetadata,    
      localStorageStrategy,
      isToday,
      formattedLastClaimedDate,
      lastClaimedStore,
      lastClaimedDate,
      lastClaimedEmployee,      
      isRewardRedeemed,
      nearestStoreDistance,
      requestLocation,
      nearestStore,      
      phoneHint,
      shouldAnimate,
      shouldApplyEffects,
      shouldApplyEffectsWithDelay,
      formattedPhone,
      formattedPhoneNumberNearestStore,
      form,
      isSending,
      isPhoneValidated,
      userAuthenticated,
      user,
      dialog,
      step,
      items,
      errors,
      snackbar,
      formSubmitted,
      verificationSent,
      requiredRules,
      emailRules,
      phoneRules,
      birthdayRules,
      validateBirthday,
      validateField,
      nextStep,
      prevStep,
      sendVerificationCode,
      twillio_sid,
      twillio_to,
      verifyCode,
      submitForm,
      emailField,
      fullNameField,
      phoneField,
      verificationCodeField,
      birthdayField,
      qrCode,
      removeContainerClass,
      getNextButtonText,
      handleNextStep: nextStep,
      formatDate,
      logout,
      icons: {
        mdiEmail,
        mdiPhone,
        mdiCake,
        mdiCalendar,
        mdiLogout,
      },
      detailsVisible,
      toggleDetails,
      getRewardPassMessage,
      qrStatus,
    };
  },
};

</script>
<style>
.v-stepper-header{
  display:none !important
}

</style>
<style scoped>
.stepper-actions {
  display: flex;
  justify-content: space-between;
}

.v-stepper__button--full {
  width: 100%;
  justify-content: center;
  background: #232e38 !important;
  width: 98%;
  color: white !important;  
}

.fade-out {
  display: none !important;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.nearest-store-details {
  margin-top: 20px !important;
  padding: 20px;
  background-color: #f4f6f8;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);  
  border-radius: 8px;
}

.store-info-table {
  width: 100%;
  border-collapse: collapse;
}

.store-info-table td {
  padding: 15px 20px;
}

.store-info-table td:first-child {
  width: 40px;
}

.store-info-table v-icon {
  font-size: 24px;
}

.store-name td {
  font-size: 1.3rem; /* Larger font size */
  font-weight: bold;
  color: #232e38;
}

.store-distance td, .store-address td, .store-location td, .store-phone td {
  font-size: 1.1rem; /* Larger font size */
  color: #555;
}

.store-distance v-icon, .store-address v-icon, .store-location v-icon, .store-phone v-icon {
  color: #232e38;
}

.store-name v-icon {
  color: #232e38;
}

@media (max-width: 600px) {
  .nearest-store-details {
    padding: 15px;
  }
  
  .store-name td {
    font-size: 1.1rem;
  }

  .store-distance td, .store-address td, .store-location td, .store-phone td {
    font-size: 1rem;
  }

  .store-info-table td {
    padding: 10px 15px;
  }
}

.store-name {
  font-size: 1.25rem;
  font-weight: bold;
  color: #232e38;
  margin-bottom: 20px; /* Add margin for better spacing */
}

.store-distance, .store-address, .store-location, .store-phone {
  font-size: 1rem;
  color: #555;
}

.store-distance .text, .store-address .text, .store-location .text, .store-phone .text {
  flex-grow: 1;
}

.nearest-store-details v-icon {
  flex-shrink: 0;
}

.nearest-store-details .text {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.store-name {
  font-size: 1.25rem;
  font-weight: bold;
  color: #232e38;
}

.store-distance, .store-address, .store-location, .store-phone {
  font-size: 1rem;
  color: #555;
}
.full-screen-app, .full-screen-main, .v-container, .v-row, .full-screen-card {
  width: 100% !important;
  height: auto !important; /* Allow height to adjust based on content */
  min-height: 100vh !important;
  margin: 0 !important;
  padding: 0 !important;
}

.v-main {
  overflow-y: auto; /* Enable vertical scrolling */
}

/* Additional styles for the nearest store section */
.nearest-store-title {
  background-color: #232e38;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 15px;
  height: 42px;
  width: 100%;
  margin-top: 25px;
}
.v-main {
  overflow-y: auto;
}

.user-info-container {
  position: fixed;
  top: 10px;
  left: 10px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
  z-index: 1000;
}

.user-info-container.user-details-visible {
  top: 0;
  left: 0;
  width: 100%;
  height: 40vh;
  border-radius: 0;
  padding: 20px;
  background: linear-gradient(135deg, #ffffff, #ffffff);
}

.user-icon {
  font-size: 3rem;
  cursor: pointer;
}

.mdi-white {
  color: white;
}

.user-details {
  display: none;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
  flex-direction: column;
  justify-content: center;
}

.user-details-visible .user-details {
  display: flex;
  max-height: 100%;
  opacity: 1;
}

.user-details ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.user-details li {
  font-size: 1.2rem;
  margin: 24px 0;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.close-icon {
  cursor: pointer;
  margin-bottom: 20px;
}

.logout-link {
  cursor: pointer;
  color: black;
}

.qr-code {
  width: 100%;
  max-width: 400px;
  aspect-ratio: 1;
}

@font-face {
  font-family: 'Osiris';
  src: url('@/assets/Osiris.otf') format('opentype');
  font-display: swap;
}

.v-btn {
  background-color: #f5f5f5;
  color: #333;
  border: none;
  box-shadow: none;
  text-transform: uppercase;
}

.v-btn--contained {
  min-width: 100px;
  height: 36px;
  padding: 0 16px;
  margin: 4px;
}

.v-stepper__step--active {
  background-color: #ffffff;
}

.v-stepper__step--inactive {
  background-color: #cccccc;
}

.v-stepper__step--editable {
  cursor: pointer;
}

.v-btn__overlay, .v-btn__underlay {
  position: relative !important;
}

.osiris-font {
  font-family: 'Osiris', sans-serif;
}

.title-logo-class {
  height: 250px !important;
}

.v-application {
  font-family: Arial, sans-serif;
}

.title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  text-align: center;
}

.custom-card {
  border-radius: 0px !important;
  background-size: 200% 200%;
}

.reward-title {
  position:relative;
  background-color: #232e38;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 28px auto 0; /* Center horizontally */
  border-radius: 5px;
  padding: 15px;
  height: 60px;
  flex-direction: column;
  padding: 25px;
  margin-top: 25px;
  margin-bottom:15px;
}

.custom-stepper {
  padding: 14px;
  margin-top: 11px;
}

.qr-code-container {
  margin-top: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

.qr-code {
  width: 100%;
  height: auto;
  max-width: 100%;
  aspect-ratio: 1;
}

.v-toolbar__content, .v-toolbar__extension {
  width: unset;
}

.v-card-title {
  padding: 0;
  margin-bottom: 16px;
}

.v-text-field__slot {
  font-size: 1rem;
}

.next-btn {
  font-size: 1.5rem;
  text-transform: uppercase;
  height: 50px !important;
  font-family: 'Osiris', sans-serif !important;
}

.v-toolbar__content > .v-toolbar-title {
  margin-inline-start: 0px !important;
}

.v-footer .text-center {
  font-size: 0.875rem;
  color: #666;
  cursor: pointer;
  text-transform: uppercase;
}

.v-card-text {
  margin-top: -50px;
}

.no-padding {
  padding: 0 !important;
}

.background-image {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.v-dialog {
  transition: opacity 0.5s ease-in-out;
}

.v-dialog-enter-active,
.v-dialog-leave-active {
  opacity: 1;
}

.v-dialog-enter,
.v-dialog-leave-to {
  opacity: 0;
}

.v-img {
  max-width: 100%;
}
 
.delay-200ms {
  animation-delay: 0.2s;
}
.fade-leave-active {
  animation: fadeOut 1s;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.trippy-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #ff007a, #1f51ff, #00ff88, #ff007a);
  background-size: 400% 400%;
  animation: backgroundShift 8s ease infinite;
  border-radius: 50%; /* Added to create soft edges */
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); /* Soft shadow effect */
}

svg {
  width: 80%;
  max-width: 120px;
  animation: rotate 2s linear infinite;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.5)); /* Add soft shadow to SVG */
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes backgroundShift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.custom-hint {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  margin-top: 8px;
}
.custom-reward-message {
  text-align: center;
  font-size: 16px;
  color: #333;
  margin-top: 20px;
  padding: 20px;
  border-radius: 10px;
  background-color: #f4f6f8;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  margin: auto;
  width: 100%;
}

.custom-reward-message p {
  margin: 10px 0;
}

.celebration-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px; /* Increased spacing */
}

.celebration-footer v-icon {
  margin: 0 10px; /* Increased spacing */
}

@media (max-width: 600px) {
  .custom-reward-message {
    font-size: 14px;
    padding: 15px;
  }

  .custom-reward-message p {
    margin: 8px 0;
  }
}
.custom-stepper {
  padding: 14px;
  margin-top: 11px;
}

.title-video-class {
  max-width: 90%; 
  margin-top: -30px;
  padding-bottom: 24px;  
}

</style>